<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  //   getCookie() {
  //     this.arr = document.cookie.split("; ");
  //     for (var i = 0; i < this.arr.length; i++) {
  //       var arr1 = this.arr[i].split("="); //将名/值对以“=”分割开
  //       if (arr1[0] == "tools_remember") {
  //         this.result = arr1[1]; //如果名为name,则结果result为名对应的值
  //       }
  //     }
  //     this.cookie = this.bar.concat(" ", this.result);
  //     sessionStorage.setItem("token", this.cookie);
  //     if (this.cookie.length < 8) {
  //       window.location.replace("https://seat.dc-eve.com");
  //     }
  //   },
  //   //判断是不是管理
  //   async getadmin() {
  //     const { data: res } = await this.$http({
  //       method: "get",
  //       url: "https://tools.dc-eve.com/report/esi/corp",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: sessionStorage.getItem("token"),
  //       },
  //       params: {
  //         corpId: this.qqForm.corpId,
  //         month: 10,
  //         page: 1,
  //         size: 10,
  //         year: 2023,
  //       },
  //     }).catch((err) => err);
  //     this.adminarr = res.data;
  //     console.log(this.adminarr);
  //     if (this.adminarr == null || this.adminarr.length == 0) {
  //       this.$store.state.admin = false;
  //       // console.log(this.$store.state.admin);
  //     } else {
  //       this.$store.state.admin = true;
  //       // console.log(this.$store.state.admin);
  //     }
  //   },
};
</script>

<style>
</style>
