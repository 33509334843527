import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb3JwSWQiOjk4NjQ1NTg5LCJzY29wZSI6WyJhbGwiXSwidXNlclNxdWFkcyI6W3sic3F1YWRJZCI6IjEiLCJzcXVhZE5hbWUiOm51bGwsImF1dGhvcml0eSI6IjEifSx7InNxdWFkSWQiOiIyMiIsInNxdWFkTmFtZSI6bnVsbCwiYXV0aG9yaXR5IjoiMjIifSx7InNxdWFkSWQiOiIyMyIsInNxdWFkTmFtZSI6bnVsbCwiYXV0aG9yaXR5IjoiMjMifSx7InNxdWFkSWQiOiIyNyIsInNxdWFkTmFtZSI6bnVsbCwiYXV0aG9yaXR5IjoiMjcifSx7InNxdWFkSWQiOiIzMiIsInNxdWFkTmFtZSI6bnVsbCwiYXV0aG9yaXR5IjoiMzIifSx7InNxdWFkSWQiOiIzMyIsInNxdWFkTmFtZSI6bnVsbCwiYXV0aG9yaXR5IjoiMzMifSx7InNxdWFkSWQiOiIzNiIsInNxdWFkTmFtZSI6bnVsbCwiYXV0aG9yaXR5IjoiMzYifV0sImV4cCI6MTcyNTQ0ODY5NSwiY2hhcmFjdGVySWQiOjIxMTU2NDIzMTcsImp0aSI6IjNiNzA0NDk1LWM5YzktNDhkNS05Njg4LWVhMjlhNDA4MGVkZiIsImNsaWVudF9pZCI6InNlYXQuZGMtZXZlIiwidXNlcm5hbWUiOiJ4aWFveWFvIGJhaWxvbmcifQ.WOReUTykWRxCBXUbb1xTFCXOkfFFcScGnTIFYiql5vKTcDsQqSweW7S7RQWrnNttU3ancLIRWsFoAhG2S08btCmtyXeLMtXHsCM8d0vHw1L7rcjqIGAoM3dTkhqX2UMgA9HMKoeeSnu6XJIyFRcLtq44ftGbLvgpt6faoiD5rTAns9_PhrTwzah3D-ECFHfJYW5zjWjKJDedtnlVWXq_xsxn9DZL-rXTTr0Ngg1KHJ6heuduMf9m7XhoOs6WqDHofJLctDemkDe34OvAVxMdBtMKjlLXvwYqyTUfqaP-D32qqIPrHUgl1UIvHuOe5sFpNrH8_EnXy5t3LwfC5F369w",
    admin: false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
