import Vue from 'vue'
import VueRouter from 'vue-router'
import { component } from 'vue/types/umd'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/',
    name: 'home',
    redirect: "/putkb",
    component: () => import('@/views/Maincommon.vue'),
    children: [{
      path: '/putkb',
      name: 'putKb',
      component: () => import('@/components/putKb.vue')
    },
    {
      path: '/seekb',
      name: 'seeKb',
      component: () => import('@/components/seeKb.vue')
    },
    ]
  },
  {
    path: '/',
    name: 'homeAdmin',
    redirect: "/putkbadmin",
    component: () => import('@/views/MainAdmin.vue'),
    children: [{
      path: '/putkbadmin',
      name: 'putKbAdmin',
      component: () => import('@/componentsAdmin/putKbAdmin.vue')
    },
    {
      path: '/seekbadmin',
      name: 'seeKbAdmin',
      component: () => import('@/componentsAdmin/seeKbAdmin.vue')
    },
    {
      path: '/dokbadmin',
      name: 'doKbAdmin',
      component: () => import('@/componentsAdmin/doKbAdmin.vue')
    },
    {
      path: '/add',
      name: 'add',
      component: () => import('@/componentsAdmin/addId.vue')
    },
    {
      path: '/csadmin',
      name: 'csAdmin',
      component: () => import('@/componentsAdmin/csKbAdmin.vue')
    },
    ]
  }
]

const router = new VueRouter({
  routes
})

//当前页面跳转报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};
export default router
